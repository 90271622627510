
<style lang="scss" scoped>
  .tolttip-message{
    display:none !important;
  }
</style>

<style lang="scss">
body {
  background: #fff !important;
}


</style>


<style lang="scss" scoped>
#header{
  position: relative!important;
}



.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.avatar__alicia {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 220px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

////////////////////////////////@at-root

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 860px) {
  
  .container__login {
    // height: 79vh !important;
  }
  .avatar__alicia {
    display: none;
  }
}
</style>


<template>
  <div>
   

    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
            <!-- <img src="../../assets/img/icon_exit.png" style="width: 45px" /> -->
          </router-link>
        </ul>
      </nav>
    </header>


    <div class="d-flex align-items-center container__login">
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="fullPage">
      </loading>
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="4">
          <CCard class="mx-md-4 mb-0">
            <CCardBody class="p-4">
              <CForm>
                <h1 class="title__login">Regístrate aquí</h1>
                <!-- <p class="text-muted">Create una cuenta</p> -->

                <label class="label__input">Usuario</label>
                <p v-if="isUsername" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo usuario está vacio</p>
                <CInput
                  placeholder="Usuario"
                  autocomplete="username"
                  v-model="username"
                  @keypress="validateNumberAndLetter($event)"
                  maxlength="30"
                >
                  <!-- <template #prepend-content><CIcon name="cil-user"/></template> -->
                </CInput>

              <label class="label__input">Tipo Documento</label>
              <p v-if="isDocumentType" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo tipo de documento está vacio</p>
              <select
                class="form-control"
                @change="changeDocumentType($event)"
              >
                <option value="">--Seleccionar--</option>
                <option value="dni">DNI</option>
                <option value="ruc">RUC</option>
                <option value="cex">Carnet de Extranjeria</option>
                <option value="pas">Pasaporte</option>
                <option value="cdi">CDI</option>
              </select>

                <label class="label__input">Número Documento</label>
                <p v-if="isNumeroDocumento" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo número de documento está vacio</p>
                <CInput
                  placeholder="Numero de documento"
                  autocomplete="dni"
                  v-model="numeroDocumento"
                  @keypress="validateNumber($event)"
                  @keyup="validateLength($event)"
                >
                  <!-- <template #prepend-content><CIcon name="cil-user"/></template> -->
                </CInput>

                <label class="label__input">Correo</label>
                <p v-if="isCorreo" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo correo está vacio</p>
                <CInput
                  placeholder="Correo"
                  autocomplete="email"
                  v-model="correo"
                />

                <label class="label__input">Contraseña</label>
                    <!--<p class="p__input">Debe tener almenos 6 caracteres</p>-->

                <p v-if="isPassword" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo contraseña está vacio</p>
                <CInput
                  placeholder="Contraseña"
                  type="password"
                  autocomplete="new-password"
                  v-model="password"
                  maxlength="20"
                >
                  <!-- <template #prepend-content><CIcon name="cil-lock-locked"/></template> -->
                </CInput>

                <label class="label__input">Confirmar contraseña</label>
                <p v-if="isRepeatPassword" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo repetir contraseña está vacio.</p>
                <CInput
                  placeholder="Repetir contraseña"
                  type="password"
                  autocomplete="new-password"
                  class="mb-4"
                  v-model="repeatPassword"
                >
                  <!-- <template #prepend-content><CIcon name="cil-lock-locked"/></template> -->
                </CInput>
                
                <p v-if="isTermsAndConditions" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo terminos y condiciones no ha sido seleccionado.</p>
                <div class="checkbox">
                  <input type="checkbox" v-model="termsAndConditions" value="1"><span style="padding:0 0 0 6px;color:black;">Acepto <span @click="$bvModal.show('modal-xl')" style="color:blue;cursor:pointer;">términos y condiciones</span></span>
                </div>
                <br>
                <button type="button" class="btn-comercio btn__ingresar" @click="registerUser()">Registrarme</button>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
  <img class="avatar__alicia" src="../../assets/img/alicia_3.jpg" />

  <b-modal id="modal-xl" size="xl" hide-footer no-close-on-backdrop hide-header-close no-close-on-esc>
    <template #modal-title>
      TÉRMINOS Y CONDICIONES DEL CHATBOT DE LAS SUSCRIPCIONES DE EL COMERCIO.
    </template>
    <div class="d-block text-center">
      <!-- <h3>Hello From This Modal!</h3> -->
      <div class="text-left">
        <p style="font-size: 16px;">

<ol type="I">
  <li>
    CONDICIONES GENERALES DE USO
    <ol>
  <li>EL CHATBOT DE LAS SUSCRIPCIONES DE EL COMERCIO (el "Servicio" o el “Chatbot”) es un bot (robot) que ofrece Empresa Editora El Comercio S.A. (“El Comercio”) en las siguientes páginas web: [•] como una plataforma de interacción con los usuarios – que podrán ser suscriptores o ex suscriptores de El Comercio (los “USUARIOS”), que permite realizar diversas consultas y autogestión de solicitudes, una vez que el USUARIO haya aceptado los términos y condiciones que El Comercio ha establecido.</li>
  <li>Los USUARIOS podrán autenticar su identidad mediante los mecanismos establecidos por El Comercio para acceder al Servicio.</li>
  <li>Los USUARIOS deberán contar las siguientes especificaciones en su móvil, tablet y/o computadora (laptop y/o de escritorio) para poder hacer uso del Chatbot:
    <br>
    <table style="width:100%;">
      <thead style="background-color:black;color:white;">
        <tr style="padding:0.5em 1.5em !important;">
          <th>Navegador Web</th>
          <th>Moviles y/o Tablets</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Mozilla Firefox 3.6 o superior
          </td>
          <td>
            Android
          </td>
        </tr>
        <tr>
          <td>
            Google chrome
          </td>
          <td>
            IOS
          </td>
        </tr>
        <tr>
          <td>
            Safari
          </td>
          <td>
          </td>
        </tr>
        <tr>
          <td>
            Internet Explorer
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
  </li>
  <li>EL USUARIO declara conocer que, para darse de alta en el Servicio y comenzar a utilizarlo, será necesario que ingrese una Clave OTP (One Time Password) que el Chatbot enviará mediante un mensaje de texto SMS a su celular o a través de un correo electrónico o ambas opciones, según los registros contenidos en la base de datos de El Comercio, siendo obligación del USUARIO mantener sus datos actualizados.</li>
  <li>El USUARIO declara conocer y acepta que: (i) al utilizar el Chatbot, El Comercio guardará los datos que se muestran en la conversación entre El Comercio y el USUARIO en sus servidores fuera o dentro de nuestro territorio (el Perú); (ii) El Comercio guarda estos datos a manera de respaldo únicamente; (iii) una vez que EL USUARIO haya ingresado la clave que le llegará por correo electrónico o SMS se considerará indubitablemente que es válida, aceptada y efectuada por él; y, (iv) el servicio de Chatbot puede sufrir caídas, lo que haría que éste deje de funcionar hasta que se reestablezca el servicio.</li>
  <li>El Chatbot es uno de los canales de atención que El Comercio pone a disposición de sus USUARIOS para realizar algunos tipos de consultas; por lo que, el USUARIO reconoce que existen otros canales de atención que permiten a los USUARIOS realizar las consultas que se ofrecen a través del Servicio.</li>
  <li>El Comercio no asumirá responsabilidad alguna por culpa leve o por cualquier problema o inconveniente imputable a terceros, sea éste de índole técnico, físico o producto de un caso fortuito o fuerza mayor que imposibilite, retrase, demore la ejecución o no permita la realización exitosa del Servicio. Asimismo, El Comercio no asumirá responsabilidad alguna por el acceso al servicio de Chatbot, a través de computadoras y/o equipos celulares de terceros y/o de acceso al público, siendo el USUARIO responsable de resguardar sus datos personales y cerrar la sesión.</li>
  <li>Cada vez que, EL USUARIO inicie una conversación con el Chatbot o luego de trascurridos 10 minutos sin que el usuario responda una conversación ya iniciada, éste deberá consultar, revisar y aceptar los Términos y Condiciones. De lo contrario, no podrá acceder al servicio de consultas y autogestión puesto a su disposición.</li>
  <li>Los Términos y Condiciones Generales del Chatbot se encontrarán a disposición del USUARIO cada vez que éste inicie una conversación o luego de trascurridos 5 minutos sin que el usuario responda una conversación iniciada; por lo que, el Chatbot le solicitará que, previamente, los acepte para continuar con el servicio. El USUARIO declara conocer que estos Términos y Condiciones podrán ser eventualmente modificados, por lo que la versión, que se encontrará disponible al inicio o continuación de cada conversación, será la vigente.</li>
  <li>EL USUARIO reconoce que las conversaciones con el Chatbot permanecerán disponibles en el historial de “Facebook Messenger” y de “Whatsapp”, salvo que las borre como medida de seguridad.</li>
  <li>EL USUARIO declara conocer que al usar el Chatbot estará hablando con un robot con inteligencia artificial, creado para poder solucionar consultas básicas y que en el caso de necesitar algo que el Chatbot no pueda hacer, podrá comunicarse desde el mismo Chat, con un chatter o con un asesor de Call Center. Asimismo, también podrá comunicarse a nuestra central por teléfono 311-5100.</li>
  <li>El USUARIO es responsable de validar que sus comunicaciones se dirijan a el Chatbot, el cual podrá reconocer en la: (i) página web de El Club El Comercio, alojada en el siguiente enlace: <a href="https://clubelcomercio.pe/" target="_blank">https://clubelcomercio.pe/</a>; y (ii) página de Facebook oficial de El Club El Comercio, ubicada en el siguiente link: <a href="https://www.facebook.com/ClubElComercioPeru" target="_blank">https://www.facebook.com/ClubElComercioPeru/</a>. En consecuencia, el USUARIO exonera de responsabilidad a El Comercio ante una suplantación del canal que no se encuentre alojado en el siguiente enlace: <a href="https://autogestion.clubelcomercio.pe" target="_blank">https://autogestion.clubelcomercio.pe</a>.</li>
  <li>El USUARIO es responsable del uso del Chatbot; por lo que, ante su mal utilización, El Comercio podrá bloquearlo, de manera temporal o permanente, de su lista de usuarios permitidos para interactuar con el Chatbot sin aviso alguno.</li>
</ol>   
  </li>
  <li>
      DATOS PERSONALES Y SEGURIDAD

<br>El USUARIO declara conocer y acepta las disposiciones sobre protección de datos personales establecidas en las Políticas de Privacidad de EL COMERCIO, que se encuentran en el siguiente enlace: <a href="https://elcomercio.pe/politicas-privacidad/" target="_blank">https://elcomercio.pe/politicas-privacidad/</a>
<br>Adicionalmente a estas políticas, el USUARIO nos otorga autorización para acceder y usar la información que se muestra en la conversación entre El Comercio y el USUARIO, con la finalidad de brindar el servicio de consultas y autogestión.
<br>EL USUARIO declara que las claves creadas o aquellas asignadas por El Comercio son de carácter personal e intransferible. No deberá compartirlas con ningún tercero ni guardarlas en el dispositivo móvil.
<br>En caso de robo, hurto o pérdida del teléfono celular o la computadora, EL USUARIO se obliga a comunicarse inmediatamente al Teléfono al 311-5100 para desafiliarse del servicio. 
<br>El USUARIO declara conocer los riesgos propios de las operaciones y consultas por internet (tales como el phishing, malware, virus, entre otros), asumiendo dichos riesgos y responsabilidades derivadas del uso del mismo.
</li>
</ol>

        </p>
      </div>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('modal-xl')"  style="padding: 7px;
    border-radius: 3px;
">Cerrar</b-button>
  </b-modal>

  </div>



  
</template>

<script>

import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import htmlToText from 'html-to-text';
import Table from '../base/Table.vue';


export default {
  name: 'Register',
  data(){
    return{
      username:"",
      correo:"",
      password:"",
      repeatPassword:"",
      isUsername:false,
      isNumeroDocumento:false,
      isDocumentType:false,
      isCorreo:false,
      isPassword:false,
      isRepeatPassword:false,
      numeroDocumento:"",
      documentType:"",
      code:"",
      isLoading: false,
      fullPage: true,
      isTermsAndConditions:false,
      termsAndConditions:false
    }
  },
  components:{
    Loading,
    Table
  },
  methods:{

    validateNumber(event){
      let val = event.key;
      let myRex = new RegExp('^[0-9]$');
      if(!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event){
      let val = event.key;
      let myRex = new RegExp('^[0-9a-zA-z]$');
      if(!myRex.test(val)) event.preventDefault();
    },
    validateLength(event){
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if(vm.documentType=="dni") len = 8;
      else if(vm.documentType=="ruc") len = 11;
      if(val.length>len){
        event.target.value = event.target.value.substring(0,len);
      }
    },
    changeDocumentType(event){
      let vm = this;
      let id = event.target.value;
      vm.documentType=id;
      vm.numeroDocumento=""
    },
    registerUser(){

      let vm = this;
      let flagUsername = false;
      let flagCorreo = false;
      let flagPassword = false;
      let flagRepeatPassword = false;
      let flagNumeroDocumento = false;
      let flagDocumentType = false;
      let flagTermsAndConditions = false;

      if(vm.username==""){
        vm.isUsername=true;
      }else{
        vm.isUsername=false;
        flagUsername=true;
      }

      if(vm.documentType==""){
        vm.isDocumentType=true;
      }else{
        vm.isDocumentType=false;
        flagDocumentType=true;
      }

      if(vm.numeroDocumento==""){
        vm.isNumeroDocumento=true;
      }else{
        vm.isNumeroDocumento=false;
        flagNumeroDocumento=true;
      }

      if(vm.correo==""){
        vm.isCorreo=true;
      }else{
        vm.isCorreo=false;
        flagCorreo=true;
      }

      if(vm.password==""){
        vm.isPassword=true;
      }else{
        vm.isPassword=false;
        flagPassword=true;
      }

      if(vm.repeatPassword==""){
        vm.isRepeatPassword=true;
      }else{
        vm.isRepeatPassword=false;
        flagRepeatPassword=true;
      }
      console.log(vm.termsAndConditions)
      if(vm.termsAndConditions==false){
        vm.isTermsAndConditions=true;
      }else{
        vm.isTermsAndConditions=false;
        flagTermsAndConditions=true;
      }

      if(flagUsername==true && flagCorreo==true && flagPassword==true && flagRepeatPassword==true && flagNumeroDocumento==true && flagDocumentType==true && flagTermsAndConditions==true){
        let data = {
          username:vm.username,
          correo:vm.correo,
          password:vm.password,
          repeatPassword:vm.repeatPassword,
          numeroDocumento:vm.numeroDocumento,
          tipoDocumento: vm.documentType
        }

      axios
        .post(`${process.env.VUE_APP_ROOT_API}/registerUsuario`,data)
        .then(function (res) {
          console.log("Respuesta =>",res);
          vm.isLoading = false;
          if(res.data.data.code==1){
            vm.$fire({
              title: "Felicitaciones",
              text: res.data.data.message,
              type: "success",
              timer: 6000
            }).then(r => {
              vm.$router.push("/login");
            });
          }else{

            $("#swal2-content").html("res.data.data.message");
            vm.$fire({
              title: "Seguridad",
              html: res.data.data.message ,
              type: "info",
              timer: 10000
            }).then(r => {
              console.log("dismin",r);
            });
          }
        }).catch(error => {
          vm.isLoading = false;
            vm.$fire({
              title: "Error",
              text: "Ocurrio un error en el servidor",
              type: "error",
              timer: 4000
            }).then(r => {
            });
        });
      }
    },
    redirectLanding(){
      let vm = this;
      vm.$router.push("/");
    },
    html2text(html){
      var tag = document.createElement('div');
      tag.innerHTML = html;
    
      return tag.innerText;
    }
  },
  mounted(){
    let vm = this;
    axios.interceptors.request.use(config => {
      vm.isLoading = true;
      return config;
    }, error => {
      return Promise.reject(error);
    });
    if(localStorage.getItem(`${process.env.VUE_APP_IDENTIFIER_LOCAL_STORAGE}sesion-appweb-comercio`)=="1"){
      vm.$router.push("/dashboard");
    }
    $("#pgb-container").hide();
  }
}
</script>